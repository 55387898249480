import { Link } from '@hh.ru/redux-spa-middleware';
import Gap from 'bloko/blocks/gap';
import Tabs, { Tab } from 'bloko/blocks/tabs';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import urlParser from 'bloko/common/urlParser';

import { NegotiationsFilterTab } from 'lux/models/applicantNegotiations/applicantNegotiationsActionsData';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

const TrlKeys = {
    [NegotiationsFilterTab.Active]: 'applicant.negotiations.filter.active',
    [NegotiationsFilterTab.All]: 'applicant.negotiations.filter.all',
};

const FiltersByActive: TranslatedComponent = ({ trls }) => {
    const filters = useSelector((state) => state.applicantNegotiationsActionsData.filters);
    const location = useSelector((state) => state.router.location);

    const url = urlParser(location.pathname);

    return (
        <Gap top bottom>
            <Tabs>
                {filters.map((tab) => {
                    url.search = tab.href;
                    return (
                        <Tab
                            id={url.href}
                            disabled={tab.selected}
                            data-qa="negotiations-tab"
                            to={url.href}
                            active={tab.selected}
                            key={tab.href}
                            Element={Link}
                        >
                            {trls[TrlKeys[tab.name]]}
                        </Tab>
                    );
                })}
            </Tabs>
        </Gap>
    );
};

export default translation(FiltersByActive);
