import { Tabs, Tab } from '@hh.ru/magritte-ui';
import { usePush } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import urlParser from 'bloko/common/urlParser';

import {
    ApplicantNegotiationStatus,
    ApplicantNegotiationFiltersStates,
    NegotiationsFilterTab,
} from 'lux/models/applicantNegotiations/applicantNegotiationsActionsData';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

const TrlKeys = {
    all: 'applicant.negotiations.filter.option.all',
    awaiting: 'applicant.negotiations.filter.option.awaiting',
    invitation: 'applicant.negotiations.filter.option.invitation',
    discard: 'applicant.negotiations.filter.option.discard',
    deleted: 'applicant.negotiations.filter.option.deleted',
    archived: 'applicant.negotiations.filter.option.archived',
};

const TOPIC_FILTERS_MAP = [
    {
        name: TrlKeys.all,
        id: ApplicantNegotiationFiltersStates.All,
        key: ApplicantNegotiationFiltersStates.All,
        queryObj: {
            filter: NegotiationsFilterTab.All,
            state: '',
        },
    },
    {
        name: TrlKeys.awaiting,
        id: ApplicantNegotiationFiltersStates.Awaiting,
        key: ApplicantNegotiationFiltersStates.Awaiting,
        queryObj: {
            filter: ApplicantNegotiationFiltersStates.All,
            state: [ApplicantNegotiationStatus.Read, ApplicantNegotiationStatus.Unread],
        },
    },
    {
        name: TrlKeys.invitation,
        id: ApplicantNegotiationFiltersStates.Invitation,
        key: ApplicantNegotiationFiltersStates.Invitation,
        queryObj: {
            filter: ApplicantNegotiationFiltersStates.All,
            state: [ApplicantNegotiationStatus.Invitation],
        },
    },
    {
        name: TrlKeys.discard,
        id: ApplicantNegotiationFiltersStates.Discard,
        key: ApplicantNegotiationFiltersStates.Discard,
        queryObj: {
            filter: ApplicantNegotiationFiltersStates.All,
            state: [ApplicantNegotiationStatus.Discard],
        },
    },
    {
        name: TrlKeys.deleted,
        id: ApplicantNegotiationFiltersStates.Deleted,
        key: ApplicantNegotiationFiltersStates.Deleted,
        queryObj: {
            filter: ApplicantNegotiationFiltersStates.Deleted,
            state: '',
        },
    },
    {
        name: TrlKeys.archived,
        id: ApplicantNegotiationFiltersStates.Archived,
        key: ApplicantNegotiationFiltersStates.Archived,
        queryObj: {
            filter: ApplicantNegotiationFiltersStates.Archived,
            state: '',
        },
    },
];
export const NegotiationsFilter: TranslatedComponent = ({ trls }) => {
    const push = usePush();
    const applicantNegotiationsCounters = useSelector((state) => state.applicantNegotiationsCounters);
    const { activeFilterTab } = applicantNegotiationsCounters;
    const { shouldShowNewAsBadge } = applicantNegotiationsCounters;

    const handleSelectTab = (id: string) => {
        const seletectedItem = TOPIC_FILTERS_MAP.find((item) => item.id === id);
        if (seletectedItem) {
            const query = urlParser.stringify(seletectedItem.queryObj);
            push(`?${query}`);
        }
    };

    return (
        <div>
            <Tabs
                activeItemId={activeFilterTab}
                onChange={handleSelectTab}
                containerCorrections={{ correctionXS: 15, correctionS: 0 }}
            >
                {TOPIC_FILTERS_MAP.map(({ name, id, key }) => {
                    const counterNew = applicantNegotiationsCounters.new[id];
                    const counterTotal = applicantNegotiationsCounters.total?.[id];
                    let counter = '';
                    if (shouldShowNewAsBadge && counterTotal && counterTotal > 0) {
                        counter = String(counterTotal);
                    }
                    if (!shouldShowNewAsBadge && counterNew > 0) {
                        counter = String(counterNew);
                    }
                    return (
                        <Tab
                            aria-label={trls[name]}
                            id={id}
                            badge={!!(shouldShowNewAsBadge && counterNew > 0)}
                            postfix={counter}
                            key={key}
                        >
                            {trls[name]}
                        </Tab>
                    );
                })}
            </Tabs>
        </div>
    );
};

export default translation(NegotiationsFilter);
