import { useState } from 'react';

import responseOfferUserStatusCloseButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/user_status/negotiation_list/response_offer_user_status_close_button_click';
import responseOfferUserStatusElementShown from '@hh.ru/analytics-js-events/build/xhh/applicant/user_status/negotiation_list/response_offer_user_status_element_shown';
import Button, { ButtonAppearance, ButtonKind, ButtonScale } from 'bloko/blocks/button';
import HSpacing from 'bloko/blocks/hSpacing';
import HSpacingContainer from 'bloko/blocks/hSpacing/HSpacingContainer';
import { CrossScaleSmallEnclosedFalse, IconColor, IconLink } from 'bloko/blocks/icon';
import Text, { TextSize } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { ApplicantNegotiationStatus } from 'lux/models/applicantNegotiations/applicantNegotiationsActionsData';
import { JobSearchStatus, JobSearchStatusForm, JobSearchStatusTrigger } from 'lux/models/applicantUserStatuses';
import useMarkAsRead from 'src/components/Applicant/JobSearchStatus/useMarkAsRead';
import useSetJobSearchStatus from 'src/components/Applicant/JobSearchStatus/useSetJobSearchStatus';
import ElementShownAnchor from 'src/components/ElementShownAnchor';
import applicantJobSearchStatusSuccess from 'src/components/Notifications/ApplicantJobSearchStatusSuccess';
import { useNotification } from 'src/components/Notifications/Provider';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import styles from './job-search-status-banner.less';

const TrlKeys = {
    header: 'applicant.negotiations.jobSearchStatusBanner.header',
    text: 'applicant.negotiations.jobSearchStatusBanner.text',
    buttonAccepted: 'applicant.negotiations.jobSearchStatusBanner.button.accepted',
    buttonThinking: 'applicant.negotiations.jobSearchStatusBanner.button.thinking',
};

const JobSearchStatusBanner: TranslatedComponent = ({ trls }) => {
    const activeTrigger = useSelector((state) => state.applicantUserStatusesTrigger);
    const { addNotification } = useNotification();
    const statusFilter = useSelector((state) => state.router.location.query?.state);
    const [isClosed, setIsClosed] = useState<boolean>(false);
    const [isDisabled, setIsDisabled] = useState<boolean>(false);
    const setJobSearchStatus = useSetJobSearchStatus(JobSearchStatusForm.ResponsesPageBanner);
    const markAsRead = useMarkAsRead();
    const needToShow = activeTrigger === JobSearchStatusTrigger.NegotiationsScreen;

    if (isClosed || !needToShow || statusFilter === ApplicantNegotiationStatus.Discard) {
        return null;
    }

    const onClose = (markRead = false) => {
        if (markRead) {
            markAsRead();
        }
        setIsClosed(true);
    };

    return (
        <ElementShownAnchor fn={responseOfferUserStatusElementShown} className={styles.bannerWrapper}>
            <VSpacing base={5} />
            <HSpacingContainer>
                <HSpacing base={5} />
                <div className={styles.banner}>
                    <div className={styles.bannerTitle}>
                        <Text size={TextSize.Large} strong>
                            {trls[TrlKeys.header]}
                        </Text>
                        <div className={styles.bannerCloseButton}>
                            <IconLink
                                onClick={() => {
                                    responseOfferUserStatusCloseButtonClick();
                                    onClose(true);
                                }}
                            >
                                <CrossScaleSmallEnclosedFalse initial={IconColor.Gray50} />
                            </IconLink>
                        </div>
                    </div>
                    <VSpacing base={2} />
                    <Text>{trls[TrlKeys.text]}</Text>
                    <VSpacing base={4} />
                    <div className={styles.bannerButtons}>
                        <Button
                            onClick={() => {
                                setIsDisabled(true);
                                void setJobSearchStatus(JobSearchStatus.AcceptedJobOffer)
                                    .then(() => {
                                        onClose();
                                    })
                                    .finally(() => {
                                        setIsDisabled(false);
                                    });
                            }}
                            disabled={isDisabled}
                            scale={ButtonScale.Small}
                            kind={ButtonKind.Success}
                        >
                            {trls[TrlKeys.buttonAccepted]}
                        </Button>
                        <Button
                            onClick={() => {
                                setIsDisabled(true);
                                void setJobSearchStatus(JobSearchStatus.HasJobOffer)
                                    .then(() => {
                                        addNotification(applicantJobSearchStatusSuccess);
                                        onClose();
                                    })
                                    .finally(() => {
                                        setIsDisabled(false);
                                    });
                            }}
                            disabled={isDisabled}
                            scale={ButtonScale.Small}
                            kind={ButtonKind.Success}
                            appearance={ButtonAppearance.Outlined}
                        >
                            {trls[TrlKeys.buttonThinking]}
                        </Button>
                    </div>
                </div>
                <HSpacing base={5} />
            </HSpacingContainer>
            <VSpacing base={5} />
        </ElementShownAnchor>
    );
};

export default translation(JobSearchStatusBanner);
