import { MouseEventHandler, useState } from 'react';
import { useDispatch } from 'react-redux';

import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { MarkAsViewedEvent } from 'Modules/Notices';
import { TopicAction, TopicActionName } from 'lux/models/applicantNegotiationTopic.types';
import ContainerForMicroFrontend from 'src/components/CodeInjector/ContainerForMicroFrontend';
import Infotip, { InfoPlacement, InfoTheme } from 'src/components/Infotip';
import { useNotification } from 'src/components/Notifications/Provider';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import doActionWithApplicantNegotiation from 'src/pages/ApplicantNegotiations/components/ApplicantNegotiations/doActionWithApplicantNegotiation';

import styles from './responses.less';

interface ListActionProps {
    action: TopicAction;
    topicId: number;
    showFeedbackTip: boolean;
}

const TrlKeys = {
    actions: {
        [TopicActionName.Decline]: 'applicant.negotiations.action.decline',
        [TopicActionName.Delete]: 'applicant.negotiations.action.delete',
        [TopicActionName.Restore]: 'applicant.negotiations.action.restore',
        [TopicActionName.Feedback]: 'applicant.negotiations.action.feedback',
    },
    feedbackTip: 'infotips.content.applicant_feedback_about_vacancy_infotip',
};

const ListAction: TranslatedComponent<ListActionProps> = ({ topicId, action, trls, showFeedbackTip }) => {
    const [disabled, setDisabled] = useState(false);
    const search = useSelector((state) => state.router.location.search);
    const dispatch = useDispatch();
    const { addNotification } = useNotification();

    const actionHandler: MouseEventHandler<HTMLButtonElement> = (event) => {
        event.stopPropagation();
        const { method, href, substate } = action;

        setDisabled(true);

        const dispatchResult = dispatch(
            doActionWithApplicantNegotiation(
                {
                    method,
                    href,
                    substate,
                    topicId,
                    search,
                },
                addNotification
            )
        );

        /**
         * Пока часть (немалая) редьюсеров написана на JS — создание стора не можем целиком унести в TS
         * Чтобы получить правильный тип для диспатча (с поддержкой thunk-action) нужно стор создавать в TS
         */
        (dispatchResult as unknown as Promise<void>).then(
            () => setDisabled(false),
            () => setDisabled(false)
        );
    };

    const renderButton = () => {
        if (action.name === TopicActionName.Feedback) {
            return <ContainerForMicroFrontend place={`topicInterviewReview${topicId}`} />;
        }
        return (
            <button
                type="button"
                disabled={disabled}
                className={styles.responsesTableAction}
                onClick={actionHandler}
                data-qa={`negotiations-${action.name}`}
            >
                {trls[TrlKeys.actions[action.name]]}
            </button>
        );
    };

    return (
        <>
            {showFeedbackTip && action.name === TopicActionName.Feedback ? (
                <Infotip
                    name="applicant_feedback_about_vacancy_infotip"
                    placement={InfoPlacement.TopStart}
                    render={() => trls[TrlKeys.feedbackTip]}
                    theme={InfoTheme.Dark}
                    show={true}
                    markAsViewedEvent={MarkAsViewedEvent.OnHide}
                >
                    {renderButton()}
                </Infotip>
            ) : (
                renderButton()
            )}
        </>
    );
};

export default translation(ListAction);
