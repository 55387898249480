import { useState, useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import topicSelectAllButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/negotiation_list/topic_select_all_button_click';
import { Link } from '@hh.ru/redux-spa-middleware';
import Button, { ButtonKind } from 'bloko/blocks/button';
import Checkbox from 'bloko/blocks/checkbox';
import Column from 'bloko/blocks/column';
import Gap from 'bloko/blocks/gap';
import BlokoLink, { LinkAppearance, LinkKind } from 'bloko/blocks/link';
import BlokoTranslateGuard from 'bloko/blocks/translateGuard';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import urlParser from 'bloko/common/urlParser';

import { Topic, TopicActionName } from 'lux/models/applicantNegotiationTopic.types';
import {
    ApplicantNegitiationsOrder,
    ApplicantNegotiationStatus,
    NegotiationsFilterTab,
    OrderDirection,
} from 'lux/models/applicantNegotiations/applicantNegotiationsActionsData';
import paths from 'src/app/routePaths';
import { useNotification } from 'src/components/Notifications/Provider';
import Pagination from 'src/components/Pagination';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import mapListOfObjects from 'src/pages/ApplicantNegotiations/utils/mapListOfObjects';

import ApplicantNegotiationsListBatchAction from 'src/pages/ApplicantNegotiations/components/ApplicantNegotiations/BatchAction';
import NegotiationsFilterByStatus from 'src/pages/ApplicantNegotiations/components/ApplicantNegotiations/FiltersByStatus';
import ApplicantNegotiationsListBanner from 'src/pages/ApplicantNegotiations/components/ApplicantNegotiations/ListBanner';
import ApplicantNegotiationsListRow from 'src/pages/ApplicantNegotiations/components/ApplicantNegotiations/ListRow';
import doActionWithApplicantNegotiation from 'src/pages/ApplicantNegotiations/components/ApplicantNegotiations/doActionWithApplicantNegotiation';

import styles from './responses.less';

const BANNER_PLACE_INDEX = 4;
const TrlKeys = {
    order: {
        [ApplicantNegitiationsOrder.LastState]: 'applicant.negotiations.order.LAST_STATE',
        [ApplicantNegitiationsOrder.LastModified]: 'applicant.negotiations.order.LAST_MODIFIED',
    },

    filter: {
        [NegotiationsFilterTab.Active]: 'vacancyResponses.empty.active',
        [NegotiationsFilterTab.All]: 'vacancyResponses.empty.all',
    },

    pageNumberWrong: 'vacancyResponses.empty.page',
    vacancy: 'applicant.negotiations.order.VACANCY',
    search: 'applicant.negotiations.search.vacancies',
};

const List: TranslatedComponent = ({ trls }) => {
    const storedLocation = useSelector((state) => state.router.location);
    const topics = useSelector((state) => state.applicantNegotiations.topicList) || [];
    const storedOrder = useSelector((state) => state.applicantNegotiationsActionsData.order);
    const paging = useSelector((state) => state.applicantNegotiations.paging);
    const deleteAction = useSelector((state) => state.applicantNegotiationsActionsData.deleteAction);
    const filterInUse = useSelector((state) => state.applicantNegotiations.filterInUse || '');

    const rawFilters = useSelector((state) => state.applicantNegotiationsActionsData.filters);
    const rawVacancies = useSelector((state) => state.vacanciesShort.vacanciesList);
    const rawManagerActivity = useSelector((state) => state.applicantEmployerManagersActivity);

    const filters = useMemo(() => mapListOfObjects(rawFilters, 'name'), [rawFilters]);
    const vacancies = useMemo(() => mapListOfObjects(rawVacancies, 'vacancyId'), [rawVacancies]);
    const managerActivityMap = useMemo(
        () => mapListOfObjects(rawManagerActivity, '@managerHhid'),
        [rawManagerActivity]
    );

    const [selectedTopics, setSelectedTopics] = useState<number[]>([]);
    const dispatch = useDispatch();
    const { addNotification } = useNotification();

    const dataQa = {
        'data-qa': `responses-list${topics.length === 0 ? ' responses-list-empty' : ''}`,
    };

    const renderOrder = useCallback(
        (orderType: ApplicantNegitiationsOrder) => {
            const order = storedOrder[orderType];
            if (!order) {
                return null;
            }
            const dataQaOrderSelected =
                order.order === OrderDirection.Ascending || order.order === OrderDirection.Descending
                    ? 'negotiations-sort-selected'
                    : '';

            const location = urlParser(storedLocation.pathname + storedLocation.search);
            const orderUrl = urlParser(order.href);
            location.params = { ...location.params, ...orderUrl.params };

            return (
                <span className={styles.responsesTableStatus}>
                    <BlokoLink
                        appearance={LinkAppearance.Pseudo}
                        to={location.href}
                        data-qa={`negotiations-sort ${dataQaOrderSelected}`}
                        kind={LinkKind.Secondary}
                        Element={Link}
                    >
                        <BlokoTranslateGuard useSpan>{trls[TrlKeys.order[order.name]]}</BlokoTranslateGuard>
                        <BlokoTranslateGuard useSpan>
                            {order.order === OrderDirection.Ascending && ' ↑'}
                        </BlokoTranslateGuard>
                        <BlokoTranslateGuard useSpan>
                            {order.order === OrderDirection.Descending && ' ↓'}
                        </BlokoTranslateGuard>
                    </BlokoLink>
                </span>
            );
        },
        [storedLocation.pathname, storedLocation.search, storedOrder, trls]
    );

    const topicChange = useCallback(
        ({ checked, topicId }: { checked: boolean; topicId: number }) => {
            const newSelectedTopics = selectedTopics.slice();

            if (checked) {
                newSelectedTopics.push(topicId);
            } else if (newSelectedTopics.includes(topicId)) {
                newSelectedTopics.splice(newSelectedTopics.indexOf(topicId), 1);
            }

            setSelectedTopics(newSelectedTopics);
        },
        [selectedTopics]
    );

    const renderTopic = useCallback(
        (topic: Topic, index: number, showFeedbackTip: boolean) => {
            const topicVacancy = vacancies[topic.vacancyId];
            const managerInactiveData = managerActivityMap[topicVacancy.employerManager['@hhid']];

            return [
                index === BANNER_PLACE_INDEX && (
                    <ApplicantNegotiationsListBanner key={`banner-${BANNER_PLACE_INDEX}`} />
                ),
                <ApplicantNegotiationsListRow
                    topic={topic}
                    topicChange={topicChange}
                    managerInactiveData={managerInactiveData}
                    vacancy={topicVacancy}
                    key={topic.id}
                    hasActiveFilter={filters.active.selected}
                    selected={selectedTopics.includes(topic.id)}
                    showFeedbackTip={showFeedbackTip}
                />,
            ];
        },
        [filters.active.selected, managerActivityMap, selectedTopics, topicChange, vacancies]
    );

    const renderPagination = useCallback(() => {
        if (!paging) {
            return null;
        }
        return (
            <div className="b-pager">
                <Gap bottom>
                    <Pagination {...paging} />
                </Gap>
            </div>
        );
    }, [paging]);

    const renderTableContent = useCallback(() => {
        let content;

        if (topics.length === 0) {
            if (urlParser(storedLocation.pathname + storedLocation.search).params.page) {
                content = trls[TrlKeys.pageNumberWrong];
            }

            if (filterInUse) {
                content = trls[TrlKeys.filter[filterInUse]];
            }

            return (
                <Gap bottom>
                    <span className={styles.responsesEmpty}>{content}</span>
                    {ApplicantNegotiationStatus.All === filterInUse.toUpperCase() && (
                        <Column xs="4" s="0" m="0" l="0" container>
                            <Gap top>
                                <Button stretched kind={ButtonKind.Primary} Element={Link} to={paths.vacancySearch}>
                                    {trls[TrlKeys.search]}
                                </Button>
                            </Gap>
                        </Column>
                    )}
                </Gap>
            );
        }

        const topicWithFeedbackTip = topics.find((topic) =>
            topic.actions?.some((action) => action.name === TopicActionName.Feedback)
        );

        return (
            <>
                <Gap bottom>
                    <div className={styles.responsesTableWrapper}>
                        <table className={styles.responsesTable}>
                            <thead>
                                <tr className={styles.responsesTableHeader}>
                                    <th className={styles.responsesTableHeaderCell}>
                                        {filters[NegotiationsFilterTab.Active].selected ? (
                                            <Checkbox
                                                checked={selectedTopics.length === topics.length}
                                                onChange={(event) => {
                                                    if (event.target.checked) {
                                                        topicSelectAllButtonClick();
                                                    }
                                                    setSelectedTopics(
                                                        event.target.checked ? topics.map(({ id }) => id) : []
                                                    );
                                                }}
                                                data-qa="negotiations-item-checkbox-all"
                                            >
                                                {renderOrder(ApplicantNegitiationsOrder.LastState)}
                                            </Checkbox>
                                        ) : (
                                            renderOrder(ApplicantNegitiationsOrder.LastState)
                                        )}
                                    </th>
                                    <th className={styles.responsesTableHeaderCell} />
                                    <th className={styles.responsesTableHeaderCell}>{trls[TrlKeys.vacancy]}</th>
                                    <th className={styles.responsesTableHeaderCell} />
                                    <th className={styles.responsesTableHeaderCell}>
                                        {renderOrder(ApplicantNegitiationsOrder.LastModified)}
                                    </th>
                                </tr>
                            </thead>
                            <tbody className={styles.responsesTableTbody}>
                                {topics.map((topic, index) =>
                                    renderTopic(topic, index, topic === topicWithFeedbackTip)
                                )}
                            </tbody>
                        </table>
                    </div>
                </Gap>
                {renderPagination()}
            </>
        );
    }, [
        filterInUse,
        filters,
        renderOrder,
        renderPagination,
        renderTopic,
        selectedTopics.length,
        storedLocation.pathname,
        storedLocation.search,
        topics,
        trls,
    ]);

    const batchRemove = useCallback(() => {
        const { href, substate } = deleteAction;

        void dispatch(
            doActionWithApplicantNegotiation(
                {
                    href,
                    substate,
                    topicId: selectedTopics.slice(),
                },
                addNotification
            )
        );

        setSelectedTopics([]);
    }, [addNotification, deleteAction, dispatch, selectedTopics]);

    return (
        <div className="HH-Pagination-ScrollToContainer" {...dataQa}>
            <Gap top bottom>
                <NegotiationsFilterByStatus />
                <ApplicantNegotiationsListBatchAction
                    show={selectedTopics.length > 0 && filters.active.selected}
                    batchRemove={batchRemove}
                />
            </Gap>
            {renderTableContent()}
        </div>
    );
};

export default translation(List);
